<template>
  <div
    class="hot-datepicker w-100"
    v-on-click-outside="onClickOutside"
    :style="styles"
    :class="{expanded: expanded}"
  >
    <template v-if="!useFaceOnly">
      <div class="vz-input">
        <label v-if="label" class="vz-input-label" v-text="label" />
        <div class="vz-input-wrapper">
          <input
            ref="inputControl"
            readonly
            type="text"
            class="hot-datepicker-control"
            :class="controlClass"
            :style="styles"
            :value="myDate"
            @click="onShowFace"
            @keydown="onKeydown"
          >
        </div>
      </div>
    </template>
    <vz-datepicker-face
      v-show="isShow || useFaceOnly"
      v-scroll="calculateListPosition"
      ref="face"
      :date="faceDate"
      :range-date="rangeDate"
      :config="myConfig"
      :lang="lang"
      :min-date="minDate"
      :max-date="maxDate"
      :centered="centered"
      :custom-dates="customDates"
      :is-show-controls="isShowControls"
      :expanded="props.expanded"
      @change="onSelectDate"
    />
  </div>
</template>

<script setup lang="ts">
import { useDayjs } from '#dayjs'
import { onMounted, PropType } from "vue"
import { DatepickerConfig } from '@/types/types'
import VzDatepickerFace from '@/components/ui-kit/vz-datepicker/vz-datepicker-face.vue'
import {useIntersectionObserver} from "@vueuse/core";

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  big: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  displayFormat: {
    type: String,
    default: 'DD.MM.YYYY'
  },
  isShowControls: {
    type: Boolean,
    default: true
  },
  expanded: {
    type: Boolean,
    default: false
  },
  useFaceOnly: {
    type: Boolean,
    default: false
  },
  lang: {
    type: String,
    default: 'ru'
  },
  config: {
    type: Object as PropType<DatepickerConfig>,
    default: () => ({})
  },
  customDates: {
    type: Array,
    default: () => ([])
  },
  minDate: {
    type: String,
    default: null
  },
  maxDate: {
    type: [String, Date],
    default: null
  },
  width: {
    type: Number,
    default: null
  },
  centered: {
    type: Boolean,
    default: false
  },
  rangeDate: {
    type: Boolean,
    default: false
  }
})

const dayjs = useDayjs()
const inputControl = ref<HTMLInputElement | null>(null)
const face = ref<any>(null)

const isShow = ref(false)

useIntersectionObserver(
    inputControl,
    ([{ isIntersecting }], observerElement) => {
      if (!isIntersecting) {
        onClickOutside()
      }
    },
)

const defaultConfig = reactive({
  locales: [
    {
      lang: 'ru',
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      daysOfWeek: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
    },
    {
      lang: 'en',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      daysOfWeek: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
    }
  ]
})

onMounted((): void => {
  emit('update:modelValue', dayjs(props.modelValue || new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'))
  calculateListPosition()
})

const faceDate = computed((): string => {
  return dayjs(props.modelValue || new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
})

const myDate = computed((): string => {
  if (dayjs(props.modelValue, 'YYYY-MM-DD').isValid()) {
    return dayjs(props.modelValue, 'YYYY-MM-DD').format(props.displayFormat)
  }
  return ''
})

const myConfig = computed((): DatepickerConfig => {
  return Object.assign(defaultConfig, props.config)
})

const styles = computed(() => {
  return {
    width: `${props.width}px`
  }
})

const controlClass = computed(() => {
  return {
    big: props.big,
    large: props.large
  }
})

const onSelectDate = (date: string): void => {
  if (isShow.value || props.useFaceOnly) {
    emit('update:modelValue', dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    emit('change', dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    isShow.value = false
  }
}

const onShowFace = (): void => {
  isShow.value = true
}

const onClickOutside = (): void => {
  if (isShow.value) {
    isShow.value = false
  }
}

const faceBoundingClientRect = (): DOMRect => {
  if (face.value.$el.style.display === 'none') {
    face.value.$el.style.display = 'block'
    const rect = face.value.$el.getBoundingClientRect();
    face.value.$el.style.display = 'none'
    return rect
  }
  return face.value.$el.getBoundingClientRect()
}

const getPosition = (left: number, width: number, faceWidth: number): 'left' | 'right' | 'center' => {
  if ((left + faceWidth) <= window.innerWidth) {
    return 'left'
  } else if (left + width >= faceWidth) {
    return 'right'
  }
  return 'center'
}

const calculateListPosition = (): void => {
  const rect = faceBoundingClientRect()
  const { top, height, width, left, right } = inputControl.value?.getBoundingClientRect() || { top: 0, height: 0, width: 0, left: 0, right: 0 }

  if (rect) {
    const fullHeight = top + height + rect.height
    const position = getPosition(left, width, rect.width)

    if (position === 'center' || props.centered) {
      (face.value.$el as HTMLElement).style.left = `${(left + (width / 2)) - (rect.width / 2)}px`
    } else if (position === 'right') {
      (face.value.$el as HTMLElement).style.left = `${right - rect.width}px`
    } else {
      (face.value.$el as HTMLElement).style.left = `${left}px`
    }

    if (fullHeight > window.innerHeight) {
      (face.value.$el as HTMLElement).style.top = `${top - rect.height}px`;
      // (face.value.$el as HTMLElement).style.boxShadow = '2px -2px 4px rgba(0, 0, 0, 0.15)'
    } else {
      (face.value.$el as HTMLElement).style.top = `${top + height}px`;
      // (face.value.$el as HTMLElement).style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }
  }
}

function onKeydown(event: KeyboardEvent): void {
  if (event.code === 'Escape' && isShow.value) {
    isShow.value = false
    event.stopPropagation()
  }
}
</script>
