<template>
  <div
    class="hot-datepicker-face" ref="$el"
    v-on-click-outside="onClickOutside"
    :class="{expanded: props.expanded}"
  >
    <div class="hot-datepicker-face-control" v-if="isShowControls">
      <vz-icon
        name="chevron-left"
        class="vz-cursor-pointer"
        @click="toPrevious"
      />
      <div class="hot-datepicker-face-control-header" @click="selectNextFaceType">
        {{ headerText }}
      </div>
      <vz-icon
        name="chevron-right"
        class="vz-cursor-pointer"
        @click="toNext"
      />
    </div>

    <div class="hot-datepicker-face-daysofweek w-100"
         :class="{ 'scale-1': isDateFace, 'flex-space-between': customDates.length }">
      <template v-if="!customDates.length">
        <div
          v-for="dayOfWeak of daysOfWeak"
          class="hot-datepicker-face-daysofweek-day"
          :key="dayOfWeak"
        >
          <span>{{ dayOfWeak }}</span>
        </div>
        <div class="hot-datepicker-face-day">
          <div v-for="(days, i) of daysList" :key="i" class="hot-datepicker-face-day-line">
            <div
              class="day"
              v-for="({ value, selected, current, inactive, fullDate, disabled }, index) of days"
              :key="index"
              :class="{ selected, current, inactive, disabled }"
              @click.stop="onSelectDay(fullDate, disabled)"
            >
              <span>{{ value }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-end flex-wrap w-100">
          <div class="flex">
            <div
              v-for="dayOfWeak of daysOfWeak"
              class="hot-datepicker-face-daysofweek-day"
              :key="dayOfWeak">
              <span>{{ dayOfWeak }}</span>
            </div>
          </div>
          <div class="vz-expanded-month flex w-100 flex-end" v-for="( month, index )  of generateCustomDates()"
               :key="index">
            <div class="flex flex-center flex-grow-1">
              <span class="color-primary">{{ month.monthName }}</span>
            </div>
            <div class="hot-datepicker-face-day">
              <div
                v-for="(days, i) of month.days" :key="i"
                class="hot-datepicker-face-day-line">
                <div
                  v-for="(day, index) of days"
                  class="day"
                  :key="index"
                  v-bind="getDayAttrs(day)"
                  @click.stop="onSelectDay(day.fullDate, day.disabled)"
                >
                  <span>{{ day.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="hot-datepicker-face-month" :class="{ 'scale-1': isMonthFace }">
      <span
        v-for="month of monthMatrix"
        :key="month.name"
        :class="{ selected: selectedMonth === month.name && currentYear === selectedYear }"
        @click="onSelectMonth(month)"
      >
        {{ month.name }}
      </span>
    </div>


    <div class="hot-datepicker-face-month" :class="{ 'scale-1': isYearFace }">
      <span
        v-for="year of years"
        :key="year"
        :class="{ selected: +currentYear === year }"
        @click="onSelectYear(year)"
      >
        {{ year }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, PropType, watch } from "vue"
import { DatepickerConfig, DatepickerFace, DatepickerMonth, DatepickerDay } from '~/types/types'
import { useDayjs } from '#dayjs'

const emit = defineEmits(['change'])

const props = defineProps({
  date: {
    type: String,
    default: ''
  },
  minDate: {
    type: [String, Date],
    default: null
  },
  isShowControls: {
    type: Boolean,
    default: true
  },
  expanded: {
    type: Boolean,
    default: true
  },
  maxDate: {
    type: [String, Date],
    default: null
  },
  lang: {
    type: String,
    default: 'ru'
  },
  customDates: {
    type: Array as PropType<any[]>,
    default: () => ([]),
  },
  centered: {
    type: Boolean,
    default: false
  },
  config: {
    type: Object as PropType<DatepickerConfig>,
    default: () => ({})
  }
})

const dayjs = useDayjs()
const face = ref<HTMLDivElement | null>(null)
const $el = ref<HTMLDivElement | null>(null)
const localValue: Ref<string> = ref('')
const datepickerType: Ref<DatepickerFace> = ref('day')
const yearOffset = ref(0)

watch(() => props.date, (date, oldDate) => {
  if (date !== oldDate && date) {
    if (dayjs(date, 'YYYY-MM-DD').isValid()) {
      localValue.value = date
    }
  }
}, {immediate: true})

function getLocalValueDayJs() {
  return dayjs(localValue.value, 'YYYY-MM-DD')
}

const isYearFace = computed(() => {
  return datepickerType.value === 'year'
})

const isMonthFace = computed(() => {
  return datepickerType.value === 'month'
})

const isDateFace = computed((): boolean => {
  return datepickerType.value === 'day'
})

const isValidDate = computed((): boolean => {
  return getLocalValueDayJs().isValid()
})

const daysOfWeak = computed((): string[] | undefined => {
  const locale = props.config.locales.find(l => l.lang === props.lang)
  if (locale) {
    return locale.daysOfWeek
  }
  return []
})

const locale = computed(() => {
  return props.config.locales.find(l => l.lang === props.lang)
})

const currentYear = computed((): string => {
  if (!isValidDate.value) {
    return ''
  }
  return dayjs(props.date, 'YYYY-MM-DD').format('YYYY')
})

const selectedYear = computed((): string => {
  if (!isValidDate.value) {
    return ''
  }
  return datepickerType.value !== 'year' ? getLocalValueDayJs().format('YYYY') : currentYear.value
})

const selectedMonth = computed((): string | undefined => {
  if (!isValidDate.value) {
    return ''
  }
  const month = getLocalValueDayJs().month()
  return locale.value?.months[month]
})

const headerText = computed(() => {
  if (isDateFace.value) {
    return `${selectedYear.value} ${selectedMonth.value}`
  }
  if (isMonthFace.value) {
    return selectedYear.value
  }
  return getLocalValueDayJs().format('DD.MM.YYYY')
})

const monthMatrix = computed((): DatepickerMonth[] => {
  let monthIndex = 0
  return locale.value?.months?.map(month => ({
    name: month,
    index: monthIndex++
  })) || []
})

function isMin(date: string) {
  if (props.minDate) {
    return dayjs(date).isBefore(props.minDate, 'day')
  }
  return false
}


function isMax(date: string) {
  if (props.maxDate) {
    return dayjs(date).isAfter(props.maxDate, 'day')
  }
  return false
}

const years = computed(() => {
  const result: number[] = []
  let djs = getLocalValueDayJs()
    .add(yearOffset.value * 12, 'year')
    .subtract(4, 'year')

  for (let i = 0; i < 12; i++) {
    result.push(djs.year())
    djs = djs.add( + 1, 'year')
  }
  return result
})

function onSelectDay(date: string, disabled: boolean): void {
  if (!disabled) {
    emit('change', date)
  }
}

function onSelectMonth(djs: DatepickerMonth) {
  localValue.value = getLocalValueDayJs().set('month', djs.index).format('YYYY-MM-DD')
  resetFace()
}

function onSelectYear(year: number) {
  localValue.value = getLocalValueDayJs().set('year', year).format('YYYY-MM-DD')
  datepickerType.value = 'month'
}

const daysList = computed(() => {
  const defaultFormat = 'YYYY-MM-DD'
  const days: DatepickerDay[][] = []
  const tempDays: DatepickerDay[] = []
  const firstWeek = getLocalValueDayJs().startOf('month').startOf('week')
  const lastWeek = getLocalValueDayJs().endOf('month').endOf('week')
  const firstDate = firstWeek.day(1)
  let djs = dayjs(firstDate.format(defaultFormat), 'YYYY-MM-DD')
  const countDays = lastWeek.diff(djs, 'days')

  for (let i = 0; i <= countDays; i++) {
    tempDays.push({
      value: djs.format('D'),
      selected: djs.format(defaultFormat) === dayjs(props.date, 'YYYY-MM-DD').format(defaultFormat),
      inactive: getLocalValueDayJs().month() !== djs.month(),
      current: djs.format(defaultFormat) === dayjs().format(defaultFormat),
      fullDate: djs.format(defaultFormat),
      disabled: isMax(djs.format(defaultFormat)) || isMin(djs.format(defaultFormat))
    })
    djs = djs.add(1, 'day')
  }

  for (let i = 0; i < Math.ceil(countDays / 7); i++) {
    const slice = tempDays.slice(i * 7, i * 7 + 7)
    if (slice) {
      days.push(slice)
    }
  }
  if (face.value) {
    face.value.style.height = datepickerType.value === 'day' ? `${Math.ceil(countDays / 7) * 35 + 100}px` : '0px'
  }
  return days
})

function generateCustomDates() {
  const defaultFormat = 'YYYY-MM-DD'
  const tempDays: DatepickerDay[] = []
  const fDay = props.customDates[0]
  const lDay = props.customDates[props.customDates.length - 1]

  const firstWeek = dayjs(fDay.date).startOf('week')
  const lastWeek = dayjs(lDay.date).endOf('week')
  const countDays = lastWeek.diff(firstWeek, 'days')

  let djs = dayjs(firstWeek, 'YYYY-MM-DD')

  for (let i = 0; i <= countDays; i++) {
    tempDays.push({
      value: djs.format('D'),
      selected: false,
      inactive: true,
      current: false,
      fullDate: djs.format(defaultFormat),
      disabled: true,
    })
    djs = djs.add(1, 'day')
  }

  tempDays.forEach((d) => {
    const date = props.customDates.find(cd => cd.date === d.fullDate)
    if (date) {
      d.disabled = date.isDisabled
      d.inactive = date.isDisabled
      d.selected = date.isDefault
      d.discount = date.isDiscount
      d.express = date.isExpress
      d.faster = date.isFaster
      d.action = date.isAction
      d.warehousing = date.hasPaidWarehousing
    }
  })
  const monthes = [{
    monthName: locale.value?.months[dayjs(tempDays?.[0]?.fullDate)?.month()] || '',
    days: [] as any
  }]

  for (let i = 0; i < Math.ceil(countDays / 7); i++) {
    const slice = tempDays.slice(i * 7, i * 7 + 7)

    if (slice) {
      const dateMonthName = locale.value?.months[dayjs(slice[0].fullDate).month()]
      const month = monthes.find(djs => djs.monthName === dateMonthName) as any
      if (month) {
        month.days.push(slice)
      } else {
        monthes.push({
          monthName: locale.value?.months[dayjs(slice[0].fullDate)?.month()] || '',
          days: [slice]
        })
      }
    }
  }
  return monthes
}

function selectNextFaceType() {
  switch (datepickerType.value) {
    case 'day':
      datepickerType.value = 'month'
      break
    case 'month':
      datepickerType.value = 'year'
      break
    case 'year':
      datepickerType.value = 'day'
      break
  }
}

function toPrevious() {
  switch (datepickerType.value) {
    case 'day':
      localValue.value = dayjs(localValue.value).subtract(1, 'month').format('YYYY-MM-DD')
      break
    case 'month':
      localValue.value = dayjs(localValue.value).subtract(1, 'year').format('YYYY-MM-DD')
      // datepickerType.value = 'year'
      break
    case 'year':
      yearOffset.value--
      break
  }
}

function toNext() {
  switch (datepickerType.value) {
    case 'day':
      localValue.value = dayjs(localValue.value).add(1, 'month').format('YYYY-MM-DD')
      break
    case 'month':
      localValue.value = dayjs(localValue.value).add(1, 'year').format('YYYY-MM-DD')
      break
    case 'year':
      yearOffset.value++
      break
  }
}

function onClickOutside() {
  localValue.value = props.date
  yearOffset.value = 0
  datepickerType.value = 'day'
}

function resetFace() {
  datepickerType.value = 'day'
}

function getDayAttrs(day: any) {
  const { selected, current, inactive, disabled, discount, faster, warehousing, express, action } = day
  const title = discount ? 'Выгодно' : faster ? 'Быстро' : warehousing ? 'Хранение' : express ? 'Экспресс' : action ? 'Акция' : ''
  return {
    title,
    class: { selected, current, inactive, disabled, discount, faster, warehousing, express, action }
  }
}

defineExpose({$el})
</script>
